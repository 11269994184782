const sites = [
  {name: 'omgcheckitout.com',	code: 'omg', branding: 'OmgCheckItOut'},
  {name: 'sportzbonanza.com',	code: 'spb', branding: 'SportzBonanza'},
  {name: 'mobispirit.com',	code: 'mob', branding: 'MobiSpirit'},
  {name: 'travelermaster.com',	code: 'trm', branding: 'TravelerMaster'},
  {name: 'ourfashiontrends.com',	code: 'oft', branding: 'OurFashionTrends'},
  {name: 'hotpoptoday.com',	code: 'hpt', branding: 'HotPopToday'},
  {name: 'beautifultrendstoday.com',	code: 'bts', branding: 'BeautifulTrendsToday'},
  {name: 'ourfunnylittlesite.com',	code: 'ofl', branding: 'OurFunnyLittleSite'},
  {name: 'travelerdreams.com',	code: 'trd', branding: 'TravelerDreams'},
  {name: 'cooking4all.com',	code: 'c4a', branding: 'Cooking4All'},
  {name: 'yoursportspot.com',	code: 'yss', branding: 'YourSportSpot'},
  {name: 'foodisinthehouse.com',	code: 'fih', branding: 'FoodIsInTheHouse'},
  {name: 'watchingtvnow.com',	code: 'wtn', branding: 'WatchingTvNow'},
  {name: 'toppoptoday.com',	code: 'tpd', branding: 'TopPopToday'},
  {name: 'yourdailysportfix.com',	code: 'yds', branding: 'YourDailySportFix'},
  {name: 'thedancingcucumber.com',	code: 'tdc', branding: 'TheDancingCucumber'},
  {name: 'thestarsworldwide.com',	code: 'tsw', branding: 'TheStarsWorldWide'},
  {name: 'wallpaperdata.com',	code: 'wpd', branding: 'WallpaperData'},
  {name: 'playjunkie.com',	code: 'plj', branding: 'PlayJunkie'},
  {name: 'tworeddots.com',	code: 'twr', branding: 'TwoRedDots'},
  {name: 'parentztalk.com',	code: 'prz', branding: 'ParentzTalk'},
  {name: 'coolimba.com',	code: 'coo', branding: 'Coolimba'},
  {name: 'theprimarymarket.com',  code: 'tpm', branding: 'ThePrimaryMarket'},
  {name: 'mmosworld.com',	code: 'mmo', branding: 'MMOsWorld'},
  {name: 'luxandlush.com', code: 'lux', branding: 'LuxAndLush'},
  // {name: 'mydailymagazine.com',	code: 'mdm', branding: 'MyDailyMagazine'},
  // {name: 'tettybetty.com',	code: 'ttb', branding: 'TettyBetty'},
  // {name: 'everydaykoala.com',	code: 'edk', branding: 'EverydayKoala'},
  // {name: 'thedailyday.com',	code: 'tdd', branding: 'TheDailyDay'},
  // {name: 'falafelandcaviar.com',	code: 'fnc', branding: 'Falafel & Caviar'},
  // {name: 'entertainmentforus.com',	code: 'efu', branding: 'EntertainmentForUs'},
  // {name: 'styleourlife.com',	code: 'sol', branding: 'StyleOurLife'},
  // {name: 'ourdailybaby.com',	code: 'odb', branding: 'OurDailyBaby'},
  // {name: 'everydaymonkey.com',	code: 'edm', branding: 'EveryDayMonkey'},
  // {name: '5dwallpaper.com',	code: 'fdw', branding: '5D Wallpaper'},
  // {name: 'dailytimewaste.com',	code: 'dtw', branding: 'DailyTimeWaste'},
  // {name: 'sharingsmiles.co',	code: 'shs', branding: 'SharingSmiles'},
  // {name: 'bobshideout.com',	code: 'bho', branding: 'Bob\'sHideout'},
  // {name: 'gingerize.com',  code: 'gin', branding: 'Gingerize'},
  // {name: 'comparisonsmaster.com',	code: 'cmm', branding: 'ComparisonMaster'},
  // {name: 'alldayunicorn.com',	code: 'adu', branding: 'AllDayUnicorn'},
  // {name: 'wallstreetviral.com',	code: 'wsv', branding: 'WallstreetViral'},
  // {name: 'godsofsports.com',	code: 'gos', branding: 'GodsOfSports'},
  // {name: 'softonic.com',	code: 'sft', branding: 'Softonic'},
  // {name: 'ebaumsworld.com',	code: 'eba', branding: 'eBaum\'sWorld'},
  // {name: 'usports.org',	code: 'usp', branding: 'USports'},
  // {name: 'uinterview.com',	code: 'uin', branding: 'UInterview'},
  // {name: 'lollynews.com',	code: 'lln', branding: 'LollyNews'},
  // {name: 'calcalistech.com',	code: 'cct', branding: 'CTech'},
  // {name: 'trend-chaser.com',	code: 'tre', branding: 'TrendChaser'},
  // {name: 'giveitlove.com',	code: 'gil', branding: 'GiveItLove'},
  // {name: 'theperspective.com',	code: 'tps', branding: 'ThePerspective'},
  // {name: 'destinationtips.com',  code: 'dst', branding: 'DestinationTips'},
  // {name: 'hometalk.com',  code: 'hom', branding: 'HomeTalk'},
  // {name: 'penguinmd.com', code: 'pmd', branding: 'PenguinMD'},
];

export default sites;
